import React, { useMemo, useState, useEffect } from "react";
import { SCORE_COLUMNS } from "components/Common/Table/columns";
import { BasicTable } from "components/Common/Table/BasicTable";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { ErrorCell } from "components/Common/Table/Cell";
import Header from "components/Common/Header";
import { setSelectedTitleAtom } from "services/atom";
import { getExamSelected } from "services/api";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import ManagerForm from "components/Manager/ManagerForm";
import { TITLE_MAPPING } from "services/mapping";
import DateExcel from "components/Manager/Excel/DateExcel";
import { Loading } from "components/Common/Loading";
import Select from "components/Common/Select";

const Eval = () => {
  const [title, setTitle] = useRecoilState(setSelectedTitleAtom);
  const { data, isLoading } = useQuery(
    ["Eval", title],
    () => getExamSelected(title),
    {
      enabled: !!title.length,
      refetchOnWindowFocus: false,
    }
  );

  const [isVisible, setVisible] = useState(false);
  const [option, setOption] = useState("default");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredScoreData, setFilteredScoreData] = useState([]);
  const [scoresettings, setScoreSettings] = useState([]);
  const [selectedScoreSetting, setSelectedScoreSetting] = useState('');
  const [versionOption, setVersionOption] = useState([]);

  React.useEffect(() => {
    if (data) {
      //데이터를 필터링하고 정렬
      const filterExam = data
        .sort((a, b) => new Date(b.exam_time) - new Date(a.exam_time))
        .filter((examData) => {
          return examData.exam_class === title;
        })
        .sort((a, b) => b.exam_time - a.exam_time)
        .map((examData) => {
          const copiedData = { ...examData };
          copiedData.name = examData.name || null;
          copiedData.exam_class = t(`Title.${examData.exam_class}`);
          copiedData.exam_time = format(
            new Date(examData.exam_time),
            "yyyy/MM/dd HH:mm:ss"
          );
          return copiedData;
        });
        setFilteredScoreData(filterExam);
        extractSettings(filteredScoreData);
      const isVisible = filterExam.length !== 0;
      setVisible(isVisible);
    }
  }, [data, title]);

  const handleClick = (data) => {
    setTitle(data);
    setSelectedScoreSetting("Version");
  };
  
  //version 가져오기
  const versionOptionMemo = useMemo(() => {
    if(data === undefined)
      return new Array("Version");
    const option = Array.from(new Set(data.map((data) => {
      return data.score_setting;
    })));
    option.sort((a, b) => a - b);
    option.unshift("ALL");
    return option;
  }, [data]);

  
  useEffect(() => {
    setVersionOption(versionOptionMemo);
  }, [versionOptionMemo]);
  

  const extractSettings = (data) => {
    const uniqueSettings = [...new Set(data.map(examData => examData.score_setting))];
    setScoreSettings(uniqueSettings);
  };

  useEffect(() => {
    const value = Number(selectedScoreSetting);
    if (selectedScoreSetting.length != 0 && !isNaN(value)) {
      const filtered = filteredScoreData.filter(examData => examData.score_setting === value);
      setFilteredData(filtered);
    }  else if (title) {
      if(filteredScoreData === undefined)
        return;
      setFilteredData(filteredScoreData);
    }  else {
      setFilteredData([]);
    }
  }, [selectedScoreSetting, filteredScoreData]);

  React.useEffect(() => {
    setOption(
      title && filteredData.length === 0 ? "selctedItemNoExist" : "default"
    );
  }, [filteredData, title]);

  return (
    <ManagerForm
      type={"eval"}
      titleText={t("ManageTest")}
      itemText={t("SearchTest")}
      titleHeader={
        <Header>
          <div style={{ display: "flex", gap: "10px" }}>
          {!isLoading && (
            <>
              <Select
                value={title}
                onClick={handleClick}
                options={TITLE_MAPPING}
                title={t("CoreSkills")}
                type={"title"}
              />
              { title && (<Select
                value={selectedScoreSetting}
                onClick={setSelectedScoreSetting}
                options={versionOption}
                title={"Version"}
                small
              />
              )}
            </>
          )}
          </div>
          {!isLoading && isVisible && <DateExcel excelData={filteredData} />}
        </Header>
      }
    >
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : !isLoading && !isVisible ? (
        <ErrorCell option={option} />
      ) : (
        !isLoading && (
          <BasicTable
            columns={SCORE_COLUMNS}
            data={filteredData}
            title={title}
          />
        )
      )}
    </ManagerForm>
  );
};

export default Eval;
